/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout } from '../components/common';

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/
const Page = ({ data }) => {
  const page = data.ghostPage;

  return (
    <Layout>
      <div className="container">
        <article className="page">
          <h1 className="content-title">{page.title}</h1>

          {/* The main page content */}
          <section
            className="content-body load-external-scripts"
            dangerouslySetInnerHTML={{ __html: page.html }}
          />
        </article>
      </div>
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.shape({
    ghostPage: PropTypes.shape({
      codeinjection_styles: PropTypes.object,
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default Page;

export const postQuery = graphql`
    query($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            title,
            html
        }
    }
`;
